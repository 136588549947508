<template>
<v-row>
    <v-col cols="12" md="6">
        <!-- basic  -->
        <base-card>
            <v-card-title>
                Informations générales
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="libelle_organisation" label="Libellé de l'organisation" outlined clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="directeur" label="Directeur" outlined clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="adresses" label="Adresse" outlined clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="email" label="E-mail" outlined clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-text-field v-model="telephone" label="Téléphone" outlined clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-file-input v-model="logo_organisations" label="Logo" outlined clearable></v-file-input>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </base-card>
    </v-col>

    <v-col cols="12" md="6">
        <!-- basic  -->
        <base-card>
            <v-card-title>
                Informations localisation
            </v-card-title>
            <v-card-text>
                <v-form>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-select :items="departement" v-model="select_departement" label="Libellé Departement" item-value="id" outlined clearable @change="Search_commune">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="commune" v-model="select_commune" label="Libellé Commune" item-value="id" outlined clearable @change="Search_arrondissemet">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="arrondissement" item-value="id" v-model="select_arrondissment" label="Libellé Arrondissment" outlined clearable @change="Search_village">
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                </v-select>
                            </v-col>
                            <v-col cols="12" sm="6">
                                <v-select :items="village" item-value="id" v-model="select_village" label="Village/Ville" outlined clearable>
                                    <template slot="selection" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                    <template slot="item" slot-scope="data">
                                        {{ data.item.libelle }}
                                    </template>
                                </v-select>
                            </v-col>

                            <v-col cols="12" sm="5">
                                <v-text-field v-model="longitude" label="longitude" outlined clearable></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="5">
                                <v-text-field v-model="latitude" label="Latitude" outlined clearable></v-text-field>
                            </v-col>
                            <v-col cols="12">
                                <v-btn rounded color="primary" dark @click="Save()">
                                    Enregistrer
                                </v-btn>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-form>
            </v-card-text>
        </base-card>
    </v-col>

</v-row>
</template>

<script>
import api from '../../../../serviceApi/apiService'
export default {

    metaInfo: {
        // title will be injected into parent titleTemplate
        title: "Profil de l'organisation",
    },
    data() {
        return {
            alert: true,
            panel: [0],
            libelle_organisation: "",
            directeur: "",
            adresses: "",
            email: "",
            telephone: "",
            logo_organisations: "",

            longitude: "",
            latitude: "",

            departement: [],
            commune: [],
            arrondissement: [],
            village: [],

            // departement2: [],
            commune2: [],
            arrondissement2: [],
            village2: [],

            select_departement: "",
            select_commune: "",
            select_arrondissment: "",
            select_village: "",

        }

    },
    methods: {
        readAll_liste_departement: async function () {
            const data = await api.readAll('geolocalisation/globale');
            this.departement = data.departement;
            this.commune2 = data.commune;
            this.arrondissement2 = data.arrondissement;
            this.village2 = data.village;
        },

        readAll: async function () {
            const data = await api.readAll('profil/list-ong-one');
            console.log(data);
            this.libelle_organisation = data[0].libelle_organisation;
            this.directeur = data[0].directeur;
            this.adresses = data[0].adresses;
            this.email = data[0].email;
            this.telephone = data[0].telephone;
            this.longitude = data[0].longitude_organisations;
            this.latitude = data[0].latitude_organisations;
            //this.logo_organisations = data[0].logo_organisations;
           
        },

        Save: async function () {
            let ft = new FormData();
            ft.append('libelle_organisation', this.libelle_organisation);
            ft.append('directeur', this.directeur);
            ft.append('adresses', this.adresses);
            ft.append('email', this.email);
            ft.append('telephone', this.telephone);
            ft.append('logo_organisations', this.logo_organisations);
            ft.append('longitude_organisations', this.longitude);
            ft.append('latitude_organisations', this.latitude);
            ft.append('id_village', this.select_village);
            const data = await api.createUpdatedata('profil/update-ong-one', ft);
            if (data.success.status == 200) {
                this.showAlert(data.message, 'success');
            } else {
                this.showAlert(data.success.message, 'error');
            }
        },

        showAlert(textmessage, txticone) {
            this.$swal.fire({
                position: 'top-end',
                icon: txticone,
                title: textmessage,
                showConfirmButton: false,
                timer: 1500
            })
        },

        Search_commune() {
            this.commune = [];
            this.arrondissement = [];
            this.village = [];
            this.commune = this.commune2.filter(this.parcoure_commune);
        },
        Search_arrondissemet() {
            this.arrondissement = [];
            this.village = [];
            this.arrondissement = this.arrondissement2.filter(this.parcoure_arrondissement);
        },
        Search_village() {
            this.village = [];
            this.village = this.village2.filter(this.parcoure_village);
        },

        parcoure_commune: function (obj) {
            if (obj.id_departements == this.select_departement) {
                return true;
            } else {
                return false;
            }
        },
        parcoure_arrondissement: function (obj) {
            if (obj.id_communes == this.select_commune) {
                return true;
            } else {
                return false;
            }
        },
        parcoure_village: function (obj) {
            if (obj.id_arrondissements == this.select_arrondissment) {
                return true;
            } else {
                return false;
            }
        },

    },
    mounted() {
        this.readAll();
        this.readAll_liste_departement();
    },
}
</script>
